@import '../../assets/scss/color';
@import '../../assets/scss/variables';

.quote-note {

  background-color: $gray-quote;
  display: block;
  position: relative;

  @media only screen and (max-width: $max-breakpoint-mobile+1) {
   display: none;
  }

  .img-quote {
    max-width: 297px;

    @media only screen and (max-width: $max-breakpoint-tablet) {
      max-width: none;
      width: 100%;
    }
  }

  .info-block {
    @media only screen and (max-width: $max-breakpoint-tablet) {
      padding: 50px 1.5rem;
    }
  }

  .quote {
    font-family: $monaco;
    font-size: 1.7rem;
    line-height: 2.5rem;
    padding-left: 40px;
    padding-right: 40px;
    text-align: left;

    @media only screen and (max-width: 1023px) {
      font-size: 1.3rem;
      line-height: 1.7rem;
    }

    @media only screen and (max-width: $max-breakpoint-mobile) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .doctor-info {
    margin-top: 30px;
    text-align: right;

    p {
      margin-bottom: 0;
    }

    @media only screen and (max-width: 1023px) {

      h2 {
        font-size: 1rem;
        line-height: 1.2rem;
      }
      p {
        font-size: .9rem;
        line-height: 1.2rem;
      }
    }
  }

  // overwrite
  .row {
    width: 100%;
  }

  .react-parallax-bgimage {
    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      left:65% !important
    }
  }
}
