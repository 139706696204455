@import '../../assets/scss/variables';

.specialty-header {

  @media only screen and (max-width: $max-breakpoint-mobile+1) {
    margin-top: 30px;
  }

  p {
    text-align: justify;
  }
}