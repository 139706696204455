
@import '../../assets/scss/color';
@import '../../assets/scss/variables';

.specialty-description {

  .card-info {
    background-color: $gray-quote;
    display: inline-block;
    padding: 1.25rem;

    @media only screen and (max-width: $max-breakpoint-tablet) {
     width: 100%;
    }

    p {
      font-size: 1rem;

      @media only screen and (max-width: 842px) {
        font-size: .875rem;
        line-height: 1rem;
      }
    }
  }

  .ul-doctor-info {
    color: $magenta;
    font-size: 1.3rem;

    @media only screen and (max-width: 842px) {
      font-size: 1rem;
      line-height: .875rem;
    }

    li {
      //margin-bottom: .3125rem;
    }
  }

  .p-inline {
    margin-top: 15px;

    p,
    a {
      display: inline-block;
      font-size: 16px;
      margin: 0;
    }

    p + p {
      margin-left: 20px;
    }
  }
}
