@import '../../assets/scss/color';
@import '../../assets/scss/variables';

.header {

  .h-transparent {
    align-items: center;
    background-color: $gray-dark-top-transparent;
    display: flex;
    min-height: 7.5rem;
    position: absolute;
    width: 100%;
    z-index: 1;


    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      min-height: 5rem;
    }

    @media only screen and (max-width: $max-breakpoint-mobile+1) {

      min-height: 5rem;
      position: relative;
      background-color: rgba(58, 58, 72, 1);
    }

    .al-left {
      @media only screen and (max-width: $max-breakpoint-mobile+1) {
        text-align: right;
      }
    }
  }

  .content-logo {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @media only screen and (max-width: $max-breakpoint-mobile+1) {
      align-items: center;
    }
  }


}
