@import '../../assets/scss/color';
@import '../../assets/scss/variables';

.modalCss {
  background-color: $gray-lite;
  border-radius: 5px;
  bottom: auto;
  left: 50%;
  padding: 50px 60px;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);


  .logo-nous {
    h1, span {
      color: $default!important;
      text-shadow: none;
    }
  }

  @media only screen and (max-width: $max-breakpoint-mobile) {

    bottom: 15px;
    overflow: scroll;
    padding: 10px;
    top: 5px;
    transform: translate(-50%, 5px);
    width: 95%;

  }
}

.overlayCss {
  background-color: $black-transparent;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}
