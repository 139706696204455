$white: #fff;
$black: #000;

$gray: #ccc;
$gray-lite: #f8f8f8;
$gray-quote: #f0f0f0;
$gray-dark: #3a3a48;

$gray-blue-superlite: #fbfbfc;
$gray-blue-dark: #525866;

$default: #495057;

$magenta: #02adc6;

// transparent rgba
$white-transparent: rgba(199, 202, 232, .4);
$black-transparent: rgba(0, 0, 0, .7);
$gray-dark-transparent:rgba(25,3,9,.3);
$gray-dark-top-transparent: rgba(58,58,72,0.6);

// color class
.color_white {
  color: $white !important;
}
.color_black {
  color: $black !important;
}
.color_magenta {
  color: $magenta !important;

}


