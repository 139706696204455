@import '../../assets/scss/color';
@import '../../assets/scss/variables';

.meet-our-doctors {

  h3 {
    color: $magenta;
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-bottom: 10px;


    @media only screen and (max-width: 842px) {
      font-size: 1rem;
      line-height: 1.2rem;
    }

    @media only screen and (max-width: $max-breakpoint-mobile) {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }

  p {
    text-align: justify;
  }

  .block {
    border: 10px solid #F8F8F8;
    background-color: #f0f0f0;

    @media only screen and (max-width: $max-breakpoint-mobile) {
      border: none;
    }
  }

  .card-block {
    //border: 1px solid $gray;
    display: block;
    height: auto;
    width: 100%;
  }

  .card-info {
    background-color: $gray-quote;
    padding: 1.25rem;


    p {
      font-size: 1rem;

      @media only screen and (max-width: 842px) {
        font-size: .875rem;
        line-height: 1rem;
      }
    }
  }

  .ul-doctor-info {
    color: $magenta;
    font-size: 1.3rem;
    margin-top: 10px;

    @media only screen and (max-width: 842px) {
      font-size: 1rem;
      line-height: .875rem;
    }

    li {
      margin-bottom: .3125rem;
    }
  }

  .row {
    margin: 0 !important;
  }

  .just-center {
    justify-content: center;
  }

  .col-md-4 {
    @media only screen and (max-width: $max-breakpoint-mobile) {
      margin-bottom: 5px;
    }
  }

  .p-inline {
    margin-top: 15px;
    p,
    a {
      display: inline-block;
      font-size: 16px;
      margin: 0;
    }

    p + p {
      margin-left: 20px;
    }
  }
}
