@import './variables';

._uppercase {
  text-transform: uppercase;
}

._box-shadow {
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, .75);
}

._text-shadow {
  text-shadow: 0 2px 0 rgba(0, 0, 0, .5);
}

._float_center {
  left: 50%;
  transform: translateX(-50%);
}

.hidden {
  visible:hidden;
  display: none;
}

// ****** MARGINs
// ******************************
._margin-bottom-5 {
  margin-bottom: 5px;
}

._margin-top-5 {
  margin-top: 5px;
}

._margin-top-10 {
  margin-top: 10px;
}

._margin-top-20 {
  margin-top: 20px;
}

._margin-top-30 {
  margin-top: 30px;
}

._margin-top-40 {
  margin-top: 40px;
}

._margin-top-50 {
  margin-top: 50px;
}

._margin-top-80 {
  margin-top: 80px;
}

._margin-top-100 {
  margin-top: 100px;
}

._margin-bottom-10 {
  margin-bottom: 10px;
}

._margin-bottom-20 {
  margin-bottom: 20px;
}

._margin-bottom-30 {
  margin-bottom: 30px;
}

._margin-bottom-40 {
  margin-bottom: 40px;
}

._margin-bottom-50 {
  margin-bottom: 50px;
}

._margin-bottom-100 {
  margin-bottom: 100px;
}

._margin-right-10 {
  margin-right: 10px;
}

._margin-right-20 {
  margin-right: 20px;
}

._margin-right-30 {
  margin-right: 30px;
}

._margin-left-10 {
  margin-left: 10px;
}

._margin-left-20 {
  margin-left: 20px;
}

._margin-left-30 {
  margin-left: 30px;
}

//******* TABLET
._margin-bottom-md-10 {
  @media only screen and (max-width: $max-breakpoint-tablet) {
    margin-bottom: 10px;
  }
}

._margin-bottom-md-20 {
  @media only screen and (max-width: $max-breakpoint-tablet) {
    margin-bottom: 20px;
  }
}

._margin-bottom-md-30 {
  @media only screen and (max-width: $max-breakpoint-tablet) {
    margin-bottom: 30px;
  }
}

._margin-top-md-10 {
  @media only screen and (max-width: $max-breakpoint-tablet) {
    margin-top: 10px;
  }
}

._margin-top-md-20 {
  @media only screen and (max-width: $max-breakpoint-tablet) {
    margin-top: 20px;
  }
}

._margin-top-md-30 {
  @media only screen and (max-width: $max-breakpoint-tablet) {
    margin-top: 30px;
  }
}

._margin-top-md-40 {
  @media only screen and (max-width: $max-breakpoint-tablet) {
    margin-top: 40px;
  }
}

._margin-top-md-50 {
  @media only screen and (max-width: $max-breakpoint-tablet) {
    margin-top: 50px;
  }
}

._remove-margin-md-left {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-left: 0 !important;
  }
}

._remove-margin-md-right {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-right: 0 !important;
  }
}

//******* MOBILE
._margin-bottom-sm-10 {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-bottom: 10px;
  }
}

._margin-bottom-sm-20 {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-bottom: 20px;
  }
}

._margin-bottom-sm-30 {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-bottom: 30px;
  }
}

._margin-top-sm-10 {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-top: 10px;
  }
}

._margin-top-sm-20 {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-top: 20px;
  }
}

._margin-top-sm-30 {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-top: 30px;
  }
}

._margin-top-sm-40 {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-top: 40px;
  }
}

._margin-top-sm-50 {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-top: 50px;
  }
}

._remove-margin-sm-left {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-left: 0 !important;
  }
}

._remove-margin-sm-right {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-right: 0 !important;
  }
}

//***** PADDINGS
._col-paddings-remove {
  padding: 0;
}

._col-paddings-sm-remove {
  @media only screen and (max-width: $max-breakpoint-mobile) {
    padding: 0 !important;
  }
}
