@import '../../assets/scss/color';
@import '../../assets/scss/variables';

.footer {
  align-items: center;
  background-color: $magenta;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  h3 {
    color: $white;
    font-size: 1.5rem;
    font-weight: bold;
    width: 100%;
  }

  p,
  a {
    color: $white;
    font-size: 1rem;
    line-height: 1.25rem;

  }

  .speciality-ul {
    li {
      margin-bottom: 5px;
      margin-top: 5px;
    }

    a {
      font-family: $roboto;
      font-size: 1rem;

      &::before {
        color: $white;
        content: '>';
        direction: ltr;
        display: inline-block;
        font-size: .75rem;
        line-height: normal;
        margin-left: -13px;
        margin-right: 5px;
        position: relative;
        speak: none;
        top: -2px;
        vertical-align: middle;
      }
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .sigma {
    font-size: 1.5rem;
    line-height: 1.8rem;

    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      font-size: 1.3rem;
      line-height: 1.6rem;
    }
  }

  .footerLogo{
    color: #fff;
    font-family: "Lucida Grande", "Helvetica Neue", sans-serif;
    font-size: 2rem;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .band-phone-email{
    padding: 20px 0;
  }
}
