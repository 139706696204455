@import '../../../assets/scss/variables';
@import '../../../assets/scss/color';

.send-consult {

  margin-top: 40px;

  @media only screen and (max-width: $max-breakpoint-mobile) {
    margin-top: 20px;
  }

  label {
    margin-bottom: 5px;
    color: #666;

    @media only screen and (max-width: $max-breakpoint-mobile) {
      font-size: 12px;
    }
  }

  input,
  textarea {
    margin-bottom: 15px;

    @media only screen and (max-width: $max-breakpoint-mobile) {
      font-size: 12px;
    }
  }

  .note {
    margin-top: 20px;

    h3 {
      font-size: 12px;
      color: $black;
    }

    p {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  .email-sent {
    margin-bottom: 20px;

    strong {
      font-weight: 900;
    }
  }
}
