@import '../../assets/scss/color';
@import '../../assets/scss/variables';

.hero {

  height: 0;
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;

  @media only screen and (min-width: 1300px) {
    padding-bottom: 845px;
  }

  .over-text {
    bottom: 50px;
    position: absolute;
    z-index: 1;

    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      bottom: 50px;
    }

    @media only screen and (max-width: $max-breakpoint-mobile+1) {
      display: none;
    }


    h2 {
      color: $default;
      font-family: $avenir;
      font-size: 52px;
      font-weight: 900;
      line-height: 52px;
    }

    @media only screen and (max-width: $max-breakpoint-mobile+1) {
      ._margin-bottom-10 {
        margin: 0;
      }
    }

    @media only screen and (max-width: $max-breakpoint-mobile+1) {
      h2 {
        font-size: 23px;
      };
    }

    p {
      color: #495057;
      font-family: $lucida;
      font-size: 18px;
      text-shadow: none;

      @media only screen and (max-width: $max-breakpoint-tablet+1) {
        font-size: 16px;
      }

      @media only screen and (max-width: $max-breakpoint-mobile+1) {
        display: none;
      }
    }

    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      p {
        font-size: 16px;
      };
    }
  }

  //**********************************************************************
  // Home hero info
  //**********************************************************************
  .home-hero-content {
    display: flex;
    justify-content: flex-end;

    .t_box {
      display: block;
      width: 65%;

      @media only screen and (max-width: 950px) {
        width: 85%;
      }

      @media only screen and (max-width: 690px) {
        width: 100%;
      }
    }

    h2,
    h3 {
      color: $white;
      font-size: 60px;
      line-height: 65px;
      font-weight: 300;
      margin-bottom: 10px;
      text-shadow: 2px 2px $black;


      @media only screen and (max-width: 950px) {
        font-size: 35px;
        line-height: 40px;
      }

      @media only screen and (max-width: $max-breakpoint-mobile+1) {
        font-size: 20px;
        line-height: 29px;
        margin-bottom: 0;
      }
    }
    h2 {
      margin: 0;
    }

    h3 {
      font-size: 40px;
      line-height: 45px;

      @media only screen and (max-width: $max-breakpoint-tablet+1) {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 10px;
      }
    }

    p {
      font-size: 25px;
      line-height: 30px;
    }

    a {
      margin-right: 20px;

      @media only screen and (max-width: $max-breakpoint-tablet+1) {
        margin-right: 5px;
      }

      @media only screen and (max-width: 560px) {
        display: none;
      }
    }
  }

  //**********************************************************************
  // Doctor hero info
  //**********************************************************************
  .doctor-hero-content {

    text-align: right;
    width: 100%;

    @media only screen and (max-width: $max-breakpoint-mobile+1) {
      text-align: center;
    }

    .doctor-info {

      max-width: 600px;
      display: inline-block;

      @media only screen and (max-width: $max-breakpoint-tablet+1) {

      }

    }
  }
}
