@import './color';
@import './animation';
@import './variables';

// Button reset
button {
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;
}

.button {

  &_color {
    background-color: $magenta;
    border-radius: 7px;
    color: $white;
    display: inline-block;
    padding: 15px 25px;

    &:hover {
      @extend %transition-anim;
      background-color: #383d41;
      color: $white;
      text-decoration: none;
    }
  }

  &_over {
    &:hover,
    &:focus {
      @extend %transition-anim;
      color: $gray-lite;
    }
  }

  &_close {
    padding: 10px;
    position: absolute;
    right: 5px;
    top: 2px;
  }
}

.a {
  &_over {
    &:hover {
      @extend %transition-anim;
      color: $black !important;
      text-decoration: none;
    }
  }
}
