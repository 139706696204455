@import '../../assets/scss/color';
@import '../../assets/scss/variables';

.specialty-content {

  .specialty-block {
    display: block;
    width: 100%;

    .button_color {
      margin-top: 20px;
    }
  }

  h2 {
    color: $black;
    font-family: $avenir;
    font-size: 1.5rem;

    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      font-size: 1.5rem;
    }
  }

  h3 {
    color: $magenta;
    font-family: $avenir;
    font-size: 1rem;
    line-height: 1.2rem;

    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  p {
    text-align: justify;
    width: 100%;
  }
}

