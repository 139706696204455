@import '../../assets/scss/button';
@import '../../assets/scss/color';

//*** Common
$footerMinHeight: 30rem;

//***
.header-footer {
  background-color: $gray-lite;
  min-height: 100vh;
  position: relative;

  .content-wrap {
    //padding-bottom: 30rem;
    padding-bottom: 22.8rem;

    @media only screen and (max-width: 769px) {
      //padding-bottom: 50rem;
      padding-bottom: 24rem;
    }

    @media only screen and (max-width: 576px) {
      //padding-bottom: 50rem;
      padding-bottom: 40.9375rem;
    }

    @media only screen and (max-width: 497px) {
      //padding-bottom: 50rem;
      padding-bottom: 44.2rem;
    }

  }

  .footer {
    bottom: 0;
    // min-height: $footerMinHeight;
    // padding-top: 40px;
    position: absolute;
    width: 100%;
  }

  .content {
    margin: 0 auto;
    max-width: 75rem; // 1200px
    position: relative;
    width: 100%;
  }

  .content_margin {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

//***
.sendUp {
  background: #02adc6;
  border-top-left-radius: 25px;
  bottom: 20%;
  color: $white;
  cursor: pointer;
  right: 0;
  padding: 10px;
  position: fixed;
  transition: all 2s;
  z-index: 10;
  display: none;

  &.active {
    display: block;
  }
}
