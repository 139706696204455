@import '../../assets/scss/color';
@import '../../assets/scss/variables';

.band-phone-email {

  align-items: center;
  background-color: $gray-dark;
  display: flex;
  min-height: 3.125rem;
  width: 100%;

  p {
    color: $white;
    display: inline;
    font-family: $roboto;
    font-size: 1rem;

    @media only screen and (max-width: $max-breakpoint-tablet) {
      font-size: .85rem !important;
    }

    @media only screen and (max-width: $max-breakpoint-mobile+1) {
      margin-bottom: 10px;
    }
  }


  .content_margin {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .space-between {
    justify-content: space-between;

    @media only screen and (max-width: $max-breakpoint-tablet) {
      flex-direction: column;
    }
  }
}
