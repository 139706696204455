@import '../../assets/scss/color';
@import '../../assets/scss/variables';

.band-announce {
  background-color: $magenta;
  display: block;
  padding-bottom: 3.375rem;
  padding-top: 3.375rem;
  position: relative;

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 0;
    margin-top: .9375rem;

    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      font-size: 1.5rem;
    }

  }

  *[class^=col-] {
    text-align: center;
  }
}