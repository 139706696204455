@import './variables';
@import './color';

.module-layout {
  display: block;
  margin-top: 5.3125rem;
  position: relative;
  width: 100%;

  @media only screen and (max-width: $max-breakpoint-tablet+1) {
    margin-top: 3.5rem;
  }
  @media only screen and (max-width: $max-breakpoint-mobile+1) {
    margin-top: 35px;
  }

  .headline {
    color: $magenta;
    font-family: $avenir;
    font-size: 2.625rem;
    line-height: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;

    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      font-size: 2rem;
      line-height: 2.2rem;
      margin-bottom: 1rem;
    }

    @media only screen and (max-width: $max-breakpoint-mobile+1) {
      font-size: 1.5rem;
      line-height: 1.8rem;
      margin-bottom: 1rem;
    }
  }

  .sub-headline {
    color: $magenta;
    font-family: $avenir;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 1rem;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 1.5625rem;

    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      font-size: .875rem;
      line-height: 1.2rem;
    }
  }
}
