.quienes-somos {

  p {
    text-align: justify;
  }

  .mision,
  .vision {
    font-size: 1.5rem;
    text-align: center;
  }
}