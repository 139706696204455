// ***********************************
// ***********************************
body,
html {

  font-family: $roboto;
  font-size: 16px;
}

h1,
h2,
h3,
p {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #666;

  @media only screen and (max-width: $max-breakpoint-tablet+1) {
    font-size: .875rem;
    line-height: 1.2rem;
  }
}

h2 {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

h3 {
  font-size: 1.75rem;
  line-height: 2rem;
}
