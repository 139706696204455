// ***********************************
// FONTS
$avenir: 'Avenir', 'Helvetica Neue', sans-serif;
$roboto: 'Roboto', 'Helvetica Neue', sans-serif;
$lucida: 'Lucida Grande', 'Helvetica Neue', sans-serif;
$monaco: 'Monaco', 'Helvetica Neue', sans-serif;


// BREAKPOINTS

$max-breakpoint-tablet: 1024px;
$max-breakpoint-mobile: 425px;
