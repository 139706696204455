@import '../../assets/scss/variables';
@import '../../assets/scss/color';

.logo-nous {

  align-items: center;
  display: flex;

  .logo-holder {
    display: block;
    height: 75px;
    width: 75px;

    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      height: 55px;
    }
  }

  img {
    width: auto;
    height: 100%;
  }

  h1 {
    color: $white;
    font-family: $lucida;
    font-size: 2rem;
    margin-bottom: 10px;
    margin-top: 10px;

    .contact {
      color: $default;
    }

    @media only screen and (max-width: $max-breakpoint-tablet+1) {
      font-size: 2rem;
      margin-bottom: 3px;
      margin-top: 3px;
    }

    @media only screen and (max-width: $max-breakpoint-mobile+1) {
      font-size: 1.5rem;
    }
  }

  p {
    color: $black !important;
    font-family: $lucida;

    @media only screen and (max-width: $max-breakpoint-mobile+1) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  div {
    margin-left: 1.25rem;
    @media only screen and (max-width: $max-breakpoint-mobile+1) {
      margin-left: 0.5rem;
    }
  }

  div:first-child {
    margin-left: 0;
  }
}
